import React, { Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import LoadingScreen from "../LoadingScreen"
import ErrorScreen from "../ErrorScreen"
import { devLog, getImageUrl } from "../../utils"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../../constants/imageSizes"
import soisyLogo from "../../../static/assets/images/soisy-white.svg"
import "./InstallmentRequests.less"
import { Col, Divider, Empty, Row, Tabs } from "antd"
import pendingIcon from "../../../static/assets/images/pending.svg"
import approvedIcon from "../../../static/assets/images/check_circle.svg"
import refusedIcon from "../../../static/assets/images/do_not_disturb_on.svg"
import klarnaLogo from "../../../static/assets/images/klarna.svg"
import pagolightLogo from "../../../static/assets/images/pagolight-light.svg"
import { GET_INSTALLMENTS_QUERY } from "../../constants/queries"
import { CURRENCY_SYMBOL } from "../../constants/settings"

const InstallmentStatusBadge = ({ installment }) => {
  const { t } = useTranslation()

  const [label, icon, labelClass] = useMemo(() => {
    if (["REFUSED", "ERROR"].includes(installment.status)) {
      return [t("label:installmentStatus.refused"), refusedIcon, "refused"]
    }

    if (["PENDING"].includes(installment.status)) {
      return [t("label:installmentStatus.pending"), pendingIcon, "pending"]
    }

    if (["APPROVED", "ACCEPTED"].includes(installment.status)) {
      return [t("label:installmentStatus.approved"), approvedIcon, "approved"]
    }

    return [t("label:installmentStatus.pending"), pendingIcon, "pending"]
  }, [installment])

  return (
    <div className={"status-badge"}>
      <span className={"badge-label " + labelClass}>{label}</span>
      <img className={"badge-icon"} alt={"refused"} src={icon} />
    </div>
  )
}

const PurchaseWithInstallmentDetails = ({ data, colSettings, emptyItem }) => {
  if (!data || !data.length) {
    return emptyItem
  }
  return data.map((installment, index) => {
    let purchaseItem = installment?.user_purchase
    let itemType = purchaseItem?.item_type
    let { id, title, price } = purchaseItem[itemType] || {}

    let picture
    if (itemType === "gift_card") {
      picture = purchaseItem[itemType]?.criterion.picture
    } else if (itemType === "certification") {
      picture = purchaseItem[itemType]?.landscape_locked
    } else {
      picture = purchaseItem[itemType]?.landscape
    }

    const coverUrl = getImageUrl(picture, DEFAULT_LANDSCAPE_IMAGE_SIZE)

    return (
      <Fragment key={index}>
        <Row
          align={"middle"}
          justify={"space-between"}
          gutter={[16, 16]}
          className={"purchase-preview-wrapper"}
        >
          <Col {...colSettings}>
            <img className="product-image" alt={title} src={coverUrl} />
          </Col>
          <Col {...colSettings}>
            <p className="product-name">{title}</p>
          </Col>
          <Col {...colSettings} className="product-price">
            {price}
            {CURRENCY_SYMBOL}
          </Col>
          <Col {...colSettings} className="product-status">
            <InstallmentStatusBadge installment={installment} />
          </Col>
        </Row>
        <Divider />
      </Fragment>
    )
  })
}

const colSettings = {
  xs: 22,
  lg: 4,
}

const UserInstallmentsRequests = () => {
  const { t } = useTranslation()
  const { TabPane } = Tabs
  const { loading, error, data } = useQuery(GET_INSTALLMENTS_QUERY, {
    fetchPolicy: "network-only",
  })

  devLog({ loading, error, data })

  const soisyInstallments = useMemo(() => {
    return data?.external_payments.filter(
      installment => installment.provider === "soisy"
    )
  }, [data])

  const klarnaInstallments = useMemo(() => {
    return data?.external_payments.filter(
      installment => installment.provider === "klarna"
    )
  }, [data])

  const scalaPayInstallments = useMemo(() => {
    return data?.external_payments.filter(
      installment => installment.provider === "scalapay"
    )
  }, [data])

  const pagolightInstallments = useMemo(() => {
    return data?.external_payments.filter(
      installment => installment.provider === "pagolight"
    )
  }, [data])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <Tabs defaultActiveKey="soisy" type="card">
      <TabPane tab={<img alt={"klarna-tab"} src={klarnaLogo} />} key="klarna">
        <PurchaseWithInstallmentDetails
          colSettings={colSettings}
          data={klarnaInstallments}
          emptyItem={<Empty description={t("label:noInstallmentsFound")} />}
        />
      </TabPane>
      {/*   <TabPane
        tab={<img alt={"scalapay-tab"} src={scalaPayLogo} />}
        key="scalapay"
      >
        <PurchaseWithInstallmentDetails
          colSettings={colSettings}
          data={scalaPayInstallments}
          emptyItem={<Empty description={t("label:noInstallmentsFound")} />}
        />
      </TabPane>*/}
      <TabPane tab={<img alt={"soisy-tab"} src={soisyLogo} />} key="soisy">
        <PurchaseWithInstallmentDetails
          colSettings={colSettings}
          data={soisyInstallments}
          emptyItem={<Empty description={t("label:noInstallmentsFound")} />}
        />
      </TabPane>
      <TabPane
        tab={<img alt={"pagolight-tab"} src={pagolightLogo} />}
        key="pagolight"
      >
        <PurchaseWithInstallmentDetails
          colSettings={colSettings}
          data={pagolightInstallments}
          emptyItem={<Empty description={t("label:noInstallmentsFound")} />}
        />
      </TabPane>
    </Tabs>
  )
}

export default UserInstallmentsRequests
